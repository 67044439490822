import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <ProjectCard title="InfinitiKloud" link="https://apps.apple.com/us/app/infiniti-kloud/id1478850608?ls=1" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
External Accessories USB <br />
      <strong>React native, Objective C, Swift, Kotlin, Java </strong>
    </ProjectCard>
    <ProjectCard title="Aircrex" link="https://aircrex.com/" bg="linear-gradient(to right, #3E64FF 0%, #9EB1FF 100%)" mdxType="ProjectCard">
Fintech project <br />
      <strong>React native, ReactJS, HTML, CSS, Javascript</strong>
    </ProjectCard>
    <ProjectCard title="myFOA" link="https://apps.apple.com/us/app/myfoa/id1450586272" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Ecommerce furniture AR project <br />
      <strong>React native</strong>
    </ProjectCard>
    <ProjectCard title="MStore" link="https://codecanyon.net/item/beostore-complete-react-native-template-for-ecommerce/17010642" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
Ecommerce mobile application template <br />
      <strong>React native</strong>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      