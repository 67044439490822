import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <p>{`I have more than 5 years experienced developer, 2 years manage team and projects.`}</p>
    <ul>
      <li parentName="ul">{`Acquire expertise and various experience in web and mobile development.`}</li>
      <li parentName="ul">{`Perseverance, responsibility, hard-working, determination, Patient and willing to listen.`}</li>
      <li parentName="ul">{`Researching and study myself very strong.`}</li>
      <li parentName="ul">{`Interested in React native/ReactJS development, management, VR and AI systems recently.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      